import React, { useState } from 'react'
import './Reservation.css'
import { ethers } from 'ethers'
import { getWhitelistReservation } from '../../constants'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'

const Reservation = ({ walletBalance }) => {
  const [reserving, setReserving] = useState(false)

  const reservationAction = () => async () => {
    try {
      const reservationContract = await getWhitelistReservation()
      if (reservationContract) {
        setReserving(true)

        const reservationTxn = await reservationContract.reserveWhitelistSpot({
          value: ethers.utils.parseUnits('0.2', 'ether')
        })
        await reservationTxn.wait()

        setReserving(false)
      }
    } catch (error) {
      console.warn('ReservationAction Error:', error)
      setReserving(false)
    }
  }

  const renderReserveButton = () => {
    return (
      <div>
        {walletBalance < 0.2 && <p className="reservations-left">Insufficient wallet balance.</p>}
        {walletBalance >= 0.2 && (
          <button type="button" className="cta-button default-button" onClick={reservationAction()}>
            Purchase a Whitelist Reservation
          </button>
        )}
      </div>
    )
  }

  return (
    <div className="mint-container">
      {!reserving && renderReserveButton()}
      {reserving && <LoadingIndicator />}
    </div>
  )
}

export default Reservation
