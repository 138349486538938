import { ethers } from 'ethers'
import whitelistReservation from './abis/WhitelistReservation.json'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const PAYMENT_SPLITTER_ADDRESSES = {
  1: ZERO_ADDRESS,
  4: '0xd5b8befbf00054ac382d783614bbe4cc9c0c2599'
}

export const WHITELIST_RESERVATION_ADDRESSES = {
  1: '0xD5B8BEfBf00054Ac382D783614bbE4cC9c0C2599',
  4: '0x7b187ed2ff45bf0462072614087ef1fb281f4005'
}

export const PDC_ADDRESSES = {
  1: ZERO_ADDRESS,
  4: '0x58e68d17e54d7579c6f4ff581bc05792b65acb25'
}

export const getWhitelistReservation = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const network = await provider.getNetwork()
  const chainId = network.chainId
  const signer = provider.getSigner()
  const address = WHITELIST_RESERVATION_ADDRESSES[chainId]

  return new ethers.Contract(address, whitelistReservation.abi, signer)
}

export const isSupportedChain = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const network = await provider.getNetwork()
  const chainId = network.chainId

  if (chainId == 1 || chainId == 3) return true

  return false
}
