import { ethers } from 'ethers'
import React, { useEffect, useState } from 'react'
import './App.css'
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator'
import Reservation from './components/Reservation/Reservation'
import { getWhitelistReservation, isSupportedChainId } from './constants'

function App() {
  // State
  const [currentAccount, setCurrentAccount] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [reservationCount, setReservationcount] = useState(0)
  const [hasReservation, setHasReservation] = useState(false)
  const [isSupportedChain, setSupportedChain] = useState(true)
  const [walletBalance, setWalletBalance] = useState(0)

  // Actions
  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        console.log('Make sure you have MetaMask!')
        // need to support metamask, walletconnect, and walletlink
        setIsLoading(false)
        return
      } else {
        const accounts = await ethereum.request({ method: 'eth_accounts' })

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const network = await provider.getNetwork()
        const chainId = network.chainId
        setSupportedChain(chainId == 1 || chainId == 4)

        if (accounts.length !== 0) {
          const account = accounts[0]
          console.log('Found an authorized account:', account)
          setCurrentAccount(account)

          const balance = await provider.getBalance(account)
          setWalletBalance(ethers.utils.formatUnits(balance, 'ether'))
        } else {
          console.log('No authorized account found')
        }

        window.ethereum.on('chainChanged', function (networkId) {
          window.location.reload()
        })
      }
    } catch (error) {
      console.log(error)
    }

    setIsLoading(false)
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingIndicator />
    }

    if (!isSupportedChain) {
      return (
        <div>
          <p className="error"> Wrong network.</p>
          <button className="cta-button error-button" onClick={switchChainAction}>
            Switch to Ethereum Network
          </button>
        </div>
      )
    } else if (!currentAccount) {
      return (
        <div>
          <button className="cta-button default-button" onClick={connectWalletAction}>
            Connect Wallet To Get Started
          </button>
        </div>
      )
    } else {
      return (
        <div>
          {!hasReservation && <Reservation walletBalance={walletBalance} />}
          {hasReservation && <p className="reservations-left">You already reserved.</p>}
        </div>
      )
    }
  }

  const connectWalletAction = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        alert('Get MetaMask!')
        return
      }

      const accounts = await ethereum.request({
        method: 'eth_requestAccounts'
      })

      console.log('Connected', accounts[0])
      setCurrentAccount(accounts[0])
    } catch (error) {
      console.log(error)
    }
  }

  const switchChainAction = async () => {
    if (currentAccount) {
      const { ethereum } = window
      await ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }]
        })
        .then(
          value => {
            window.location.reload()
          },
          reason => {
            console.error(reason)
          }
        )
    }
  }

  useEffect(() => {
    setIsLoading(true)
    checkIfWalletIsConnected()
  }, [])

  useEffect(() => {
    const getReservationStatus = async () => {
      const reservationContract = await getWhitelistReservation()

      const count = await reservationContract.reservationCount()
      const status = await reservationContract.addressHasReserved(currentAccount)

      setReservationcount(count.toNumber())
      setHasReservation(status)
    }

    if (currentAccount) {
      console.log('CurrentAccount:', currentAccount)
      getReservationStatus()
    }
  }, [currentAccount])

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <div className="connect-wallet-container">
            <img
              src="https://assets.website-files.com/61eb43ade58dc65d9e203dcf/61fc95573cfd6524e6e96b8b_scribble_pdc-35.png"
              alt="PDC Logo"
            />
            <p className="sub-text">
              The cost of a Prisoner's Dilemma Club whitelist reservation is 0.2 ETH. This will entitle you to mint two
              NFTs at your convenience on or after the date of the private mint. Reservations will be limited to a total
              of 2500. Stay tuned on our socials for more details.
            </p>
            {/* {reservationCount < 2500 && (
              <p className="reservations-left">Reservations Left: {2500 - reservationCount}/2500</p>
            )} */}
            {reservationCount === 2500 && <p className="reservations-left">All reservations sold out.</p>}
            {renderContent()}
          </div>
        </div>
        <div className="footer-text">{<p className="">Prisoner's Dilemma Club 2022</p>}</div>
      </div>
    </div>
  )
}

export default App
